import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { Link, graphql, navigate } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import countries from 'countries-list';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';

import Layout from '../../components/Layout';
import Dropdown from '../../components/molecules/Dropdown';

import mondayConfig from '../../constants/monday-config';
import { POLITICA_DE_PRIVACIDAD_ROUTE } from '../../constants/routes';

import '../../assets/styles/pages/inscripcion.scss';

const monthNames = [
  'Enero',
  'Febrero',
  'Marcha',
  'Abril',
  'Mayo',
  'Junio',
  'Mes de julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map((code) => countries.countries[code].name);
const countryNamesOptions = countryNames.sort().map((country) => ({
  value: country,
  label: country,
}));

const FieldValidationErrorMsg = ({ field }) =>
  field ? <div className="error-val">{field.message}</div> : null;

const Inscripcion = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setError] = useState(null);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const watchCourse = watch('course');

  const allPrismicCourses = data.allPrismicCourses.edges;
  const allPrismicCoursesDiscountSpanishLessons = allPrismicCourses.filter(({ node: { data } }) =>
    data.show_on_these_pages.some(({ page }) => page === 'discount-spanish-lessons')
  );
  // console.log(allPrismicCourses);

  // const getCourseDates = () => {
  //   switch (watchCourse) {
  //     case 'Spanish A1':
  //       return allPrismicCourses
  //         .filter(({ node: { data } }) =>
  //           data.show_on_these_pages.some(({ page }) => page === 'discount-spanish-lessons')
  //         )
  //         .filter(({ node: { data } }) => data.name.text === 'Spanish A1')
  //         .map(({ node: { data } }) => ({
  //           value: format(new Date(data.from), 'MMMM do YYY', { locale: es }),
  //           label: format(new Date(data.from), 'd MMMM yyyy', { locale: es }),
  //         }));
  //     case 'Spanish B2':
  //       return allPrismicCourses
  //         .filter(({ node: { data } }) =>
  //           data.show_on_these_pages.some(({ page }) => page === 'discount-spanish-lessons')
  //         )
  //         .filter(({ node: { data } }) => data.name.text === 'Spanish B2')
  //         .map(({ node: { data } }) => ({
  //           value: format(new Date(data.from), 'MMMM do YYY', { locale: es }),
  //           label: format(new Date(data.from), 'd MMMM yyyy', { locale: es }),
  //         }));

  //     default:
  //       return monthNames.map((month) => ({ value: month, label: month }));
  //   }
  // };

  const getCourseTimetable = () => {
    return (
      allPrismicCoursesDiscountSpanishLessons
        .filter(({ node: { data } }) => data.name.text === watchCourse)
        .filter(({ node: { data } }) => data.timetable)
        .map(({ node: { data } }) => ({
          value: data.timetable,
          label: data.timetable,
        })) || []
    );
  };
  const onSubmit = async (data) => {
    // Enable loading
    setIsLoading(true);

    try {
      const MailchimpData = {
        EMAIL: data.userEmail,
        FNAME: data.firstName,
        LNAME: data.lastName,
        MMERGE4: data.userPhone, // Phone
        // MMERGE5: data.courseDate.value, // Course date
        MMERGE6: format(new Date(), 'MM/dd/yyyy'), // contact date - Fecha contacto format(new Date(), 'MMMM dd, yyy HH:mm', { locale: es })
        // MMERGE7: data.userNative ? 'Si' : 'No', // Native
        MMERGE7: data.userNationality.value, // Nationality
        MMERGE8: format(
          new Date(data.userDobYYYY, data.userDobMM - 1, data.userDobDD),
          'MM/dd/yyyy'
        ), // DOB
        MMERGE14: data.currentLocation,
        // MMERGE15: '', // accommodation
        MMERGE9: data.promotionalCode, // Promo code
        MMERGE10: data.comments, // Comments
        MMERGE11: data.course, // Course
        MMERGE13: data.courseTimetable.value, // Timetable
      };
      const MondayData = {
        BOARD_ID: mondayConfig.boardELEpracticeClassLeadsId,
        GROUP_ID: 'interesados_clases_pr_cticas',
        itemName: `${data.firstName} ${data.lastName}`,
        values: {
          status: { label: 'Application' }, // Source
          curso: data.course, // Course
          fecha_inicio: data.courseTimetable.value.replace(/(\r\n|\n|\r)/gm, '').trim(),
          text: data.firstName, // firstName
          text4: data.lastName, // lastName
          email8: data.userEmail,
          tel_fono: data.userPhone, // Teléfono
          long_text: data.comments, // Comentarios
          date_13: { date: `${data.userDobYYYY}-${data.userDobMM}-${data.userDobDD}` }, //Fecha nacimiento
          text7: data.userNationality.value, // Nationality - Nacionalidad
          text1: data.promotionalCode, // Promo code - Código promo
          contact_date: mondayConfig.date, // contact date - Fecha contacto

          // date4: data.courseDate.value, // Course date - Fecha inicio
          // status_26: '', // Estatus
          // email8: { email: data.userEmail, text: data.userEmail },
          // text4: data.currentLocation, // Ubicación
          // status_8: '', // accommodation - Alojamiento
          // native9: { label: data.userNative ? 'Si' : 'No' }, // Nativo
        },
      };
      const response = await fetch('/api/monday', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(MondayData),
      });
      const jsonResponse = await response.json();

      if (jsonResponse?.success && !jsonResponse?.data?.error_code) {
        setError(false);
        addToMailchimp(
          data.userEmail,
          MailchimpData,
          'https://elebarcelona.us9.list-manage.com/subscribe/post?u=14228e00bf6efc88dcde365c5&amp;id=ca3c5be6de'
        );
        setIsSuccess(true);
        if (window?.fbq) {
          window.fbq('track', 'SubmitApplication');
        }
        if (window?.gtag_report_conversion) {
          window.gtag_report_conversion();
        }
        window.location.replace('https://elebarcelona.com' + '/inscripcion/gracias');
        // window.location.replace('http://localhost:3000' + '/inscripcion/gracias');
      } else {
        throw new Error(jsonResponse.error);
      }
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
      setError(error ? error.message || error : 'Oops, something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        {/* <!-- Event snippet for Application form sign up conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->  */}
        <script>
          {`function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-1029029840/Gst2CNe8y-sCEND_1uoD', 'event_callback': callback }); return false; } `}
        </script>
      </Helmet>

      <Layout
        title="Start studying Spanish with ELE Barcelona | Apply now"
        description="Sign up to our discounted Spanish classes with trainee Spanish teachers. Courses every month."
      >
        <div className="apply-form-page">
          <section className="c-first-section c-first-section--md">
            <div className="container">
              <h1 className="title">
                Complete Our Simple, Non-Committal Application Form And We’ll Get Back To You In
                Under 12 Hours
              </h1>
              <div className="wrapper wrapper--3-group">
                <div className="group">
                  <div className="group__disc">
                    <span>1</span>
                  </div>
                  <div className="group__text">
                    <p className="text__title">Step 1</p>
                    <p className="text__description">Complete the form below</p>
                  </div>
                </div>

                <div className="group">
                  <div className="group__disc">
                    <span>2</span>
                  </div>
                  <div className="group__text">
                    <p className="text__title">Step 2</p>
                    <p className="text__description">
                      Do an oral level test (at the school or on the phone)
                    </p>
                  </div>
                </div>

                <div className="group">
                  <div className="group__disc">
                    <span>3</span>
                  </div>
                  <div className="group__text">
                    <p className="text__title">Step 3</p>
                    <p className="text__description">Start studying!</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="second-section">
            <div className="container">
              <form className="forms" onSubmit={handleSubmit(onSubmit)}>
                <div className="form">
                  <h1 className="c-title-22 form__title">Your Course</h1>
                  <h3 className="form__subtitle">What course would you like to do?</h3>
                  <div>
                    {allPrismicCoursesDiscountSpanishLessons.map(({ node }, index) => (
                      <div className="form__radio" key={index}>
                        <input
                          id={`course-radio-${index}`}
                          type="radio"
                          name="course"
                          value={node.data.name.text}
                          {...register('course', {
                            required: 'This field is required.',
                          })}
                        />
                        <label htmlFor={`course-radio-${index}`}>
                          <span className="label">{node.data.name.text}</span>
                        </label>
                      </div>
                    ))}

                    <FieldValidationErrorMsg field={errors?.course} />
                  </div>

                  <h3 className="form__subtitle start-date">
                    What timetable would you like to do?
                  </h3>
                  {/* <Controller
                    name="courseDate"
                    render={({ field }) => (
                      <Dropdown
                        options={getCourseDates()}
                        placeholder="Select your preferred course date"
                        {...field}
                      />
                    )}
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: 'This field is required.',
                    }}
                  />
                  <FieldValidationErrorMsg field={errors?.courseDate} /> */}
                  <Controller
                    name="courseTimetable"
                    render={({ field }) => (
                      <Dropdown
                        options={getCourseTimetable()}
                        placeholder="Select your preferred course date"
                        {...field}
                      />
                    )}
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: 'This field is required.',
                    }}
                  />
                  <FieldValidationErrorMsg field={errors?.courseTimetable} />
                  <h1 className="c-title-22 form__title mt-50">About you</h1>
                  <label className="form__first-name">
                    <p className="c-text-14 text-under">First name</p>
                    <input
                      className="input__first-name"
                      type="text"
                      placeholder="John"
                      name="firstName"
                      {...register('firstName', {
                        required: 'This field is required.',
                      })}
                    />
                    <FieldValidationErrorMsg field={errors?.firstName} />
                  </label>
                  <label className="form__last-name">
                    <p className="c-text-14 text-under">Last name</p>
                    <input
                      className="input__last-name"
                      type="text"
                      placeholder="Smith"
                      name="lastName"
                      {...register('lastName', {
                        required: 'This field is required.',
                      })}
                    />
                    <FieldValidationErrorMsg field={errors?.lastName} />
                  </label>
                  <label className="form__first-name">
                    <p className="c-text-14 text-under">Email</p>
                    <input
                      className="input__first-name"
                      type="email"
                      placeholder="example@gmail.com"
                      name="userEmail"
                      {...register('userEmail', {
                        required: 'This field is required.',
                      })}
                    />
                    <FieldValidationErrorMsg field={errors?.userEmail} />
                  </label>
                  <label className="form__last-name phone-number">
                    <p className="c-text-14 text-under">Phone</p>
                    {/* <input type="tel" name="userPhoneType" className="begin" placeholder="+34" /> */}
                    <input
                      className="input__last-name"
                      type="tel"
                      placeholder="+34 9165 32-32"
                      name="userPhone"
                      {...register('userPhone')}
                    />
                  </label>
                  {/* <label className="form__first-name">
                    <p className="c-text-14 text-under">Ubicación actual</p>
                    <input
                      className="input__last-name"
                      type="text"
                      placeholder="p. ej., Madrid"
                      name="currentLocation"
                      {...register('currentLocation')}
                    />
                  </label> */}
                  {/* <h3 className="form__subtitle form__subtitle--my">
                    ¿El español es tu lengua materna?
                  </h3>
                  <p className="form__par">(¡Los no-nativos pueden hacer el curso también!)* </p>
                  <div>
                    <div className="form__radio">
                      <input
                        id="english-speaker-radio-1"
                        type="radio"
                        value="Yes"
                        name="userNative"
                        {...register('userNative', {
                          required: 'This field is required.',
                        })}
                      />
                      <label htmlFor="english-speaker-radio-1">
                        <span className="label">Si</span>
                      </label>
                    </div>

                    <div className="form__radio">
                      <input
                        id="english-speaker-radio-2"
                        type="radio"
                        value="No"
                        name="userNative"
                        {...register('userNative', {
                          required: 'This field is required.',
                        })}
                      />
                      <label htmlFor="english-speaker-radio-2">
                        <span className="label">No</span>
                      </label>
                    </div>
                    <FieldValidationErrorMsg field={errors?.userNative} />
                  </div> */}
                  <h3 className="form__subtitle start-date">Nationality</h3>
                  <Controller
                    name="userNationality"
                    render={({ field }) => (
                      <Dropdown
                        options={countryNamesOptions}
                        placeholder="Select your country"
                        isSearchable
                        {...field}
                      />
                    )}
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: 'This field is required.',
                    }}
                  />
                  <FieldValidationErrorMsg field={errors?.userNationality} />

                  <div className="date">
                    <h3 className="form__subtitle">Date of birth</h3>
                    <div className="date__wrapper">
                      <input
                        type="number"
                        placeholder="01"
                        className="input__date input__date--dd"
                        name="userDobDD"
                        {...register('userDobDD', {
                          required: 'This field is required.',
                        })}
                      />
                      <span className="text-fw-600">DD</span>
                    </div>
                    <div className="date__wrapper">
                      <input
                        type="number"
                        placeholder="02"
                        className="input__date input__date--mm"
                        name="userDobMM"
                        {...register('userDobMM', {
                          required: 'This field is required.',
                        })}
                      />
                      <span className="text-fw-600">MM</span>
                    </div>
                    <div className="date__wrapper">
                      <input
                        type="number"
                        placeholder="1987"
                        className="input__date input__date--yyyy"
                        name="userDobYYYY"
                        {...register('userDobYYYY', {
                          required: 'This field is required.',
                        })}
                      />
                      <span className="text-fw-600">YYYY</span>
                    </div>
                    <FieldValidationErrorMsg
                      field={errors?.userDobDD || errors?.userDobMM || errors?.userDobYYYY}
                    />
                  </div>

                  <label className="form__first-name promotional-code">
                    <p className="c-text-14 text-under">Promo code</p>
                    <input
                      className="input__first-name"
                      type="text"
                      placeholder="Enter code"
                      name="promotionalCode"
                      {...register('promotionalCode')}
                    />
                    {/* <button className="promotional-code__btn">Apply Promotional Code</button> */}
                  </label>

                  <label className="form__textarea">
                    <p className="c-text-14 text-under">Additional comments / questions</p>
                    <textarea
                      className="input__textarea input__textarea--sm"
                      placeholder="Any comments or questions? Write here."
                      name="comments"
                      {...register('comments')}
                    />
                  </label>

                  <h3 className="form__subtitle">Terms and conditions</h3>
                  <div className="form__checkbox terms">
                    <input
                      id="agree-checkbox-1"
                      type="checkbox"
                      value={true}
                      name="agreeRules"
                      {...register('agreeRules', { required: 'This field is required.' })}
                    />
                    <label htmlFor="agree-checkbox-1">
                      <span className="label">
                        I agree to ELE Barcelona’s{' '}
                        <a href={POLITICA_DE_PRIVACIDAD_ROUTE} className="terms">
                          terms and conditions.
                        </a>
                      </span>
                    </label>
                    <FieldValidationErrorMsg field={errors?.agreeRules} />
                  </div>
                  <button
                    type="submit"
                    className="c-btn c-btn--green"
                    disabled={Object.keys(errors || {}).length > 0}
                  >
                    {isLoading ? <i className="spinner" /> : 'Submit application'}
                  </button>
                </div>

                {isSuccess && (
                  <div className="success-msg">
                    <span>Your application has been successfully submitted!</span>
                  </div>
                )}

                {isError && (
                  <div className="error-msg">
                    <span>{isError}</span>
                  </div>
                )}
                {Object.keys(errors || {}).length > 0 && (
                  <div className="error-msg">
                    <span>Some fields are missing or contain errors.</span>
                  </div>
                )}
              </form>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(sort: { order: ASC, fields: data___from }) {
      edges {
        node {
          data {
            show_on_these_pages {
              page
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            timetable
            name {
              text
            }
          }
        }
      }
    }
  }
`;

export default Inscripcion;
